import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import SEO from "../components/seo";
import Layout from "../components/layouts/layout";

export default function AvisoPrivacidad(){

    return(
        <Layout>
            <SEO
                title="Grupo Rivero Chevrolet Monterrey - Agencia Chevrolet Telefono"
                description="Agencia Chevrolet Telefono - Telefono Chevrolet - Chevrolet Seminuevos - Chevrolet linda vista - Chevrolet santa catarina - Chevrolet san pedro - Chevrolet guadalupe"
                keywords="Chevrolet Telefono, Agencia de autos, chevrolet monterrey, Chevrolet Linda Vista, Chevrolet Guadalupe, Chevrolet Santa catarina, Aveo, Beat, Spark, Onix"
                path="/aviso-de-privacidad"
            />

            <Container className="justify-content-md-center bg-white p-4">
                <Row>
                    <Col md={12} sm={12}>
                        <h1>AVISO DE PRIVACIDAD CLIENTE<br />&nbsp;</h1>
                    </Col>
                    <Col md={12} sm={12}>
                        <p>
                        <strong>Grupo Rivero</strong> está compuesto por 5 agencias y un taller de servicio, localizadas en puntos estratégicos de la ciudad y su área metropolitana, en donde manejamos las
                        mejores marcas de General Motors. En <strong>Grupo Rivero</strong> nos preocupamos por cada una de las personas que depositan su confianza en nuestras agencias, es por eso que
                        nosotros si hablamos claro y de frente con una total honestidad para brindarle la confianza de un verdadero aliado con la única finalidad de cuidar y proteger sus intereses y
                        patrimonio. Es al final del día resolver todas y cada una de sus necesidades. Es por eso que con el tratamiento legitimo, controlado e informado de sus datos personales es
                        de vital importancia para alcanzar los objetivos corporativos a través de todas las áreas del negocio reiterando nuestro compromiso con su privacidad y el derecho a la
                        autodeterminación informativa, por lo que, en cumplimiento a lo establecido en la ley federal de protección de datos personales en posesión de los particulares ponemos a su
                        disposición nuestro <strong>AVISO DE PRIVACIDAD</strong>.
                        </p>

                        <h3><br /><strong>A. NOMBRE Y DOMICILIO DEL RESPONSABLE</strong></h3>
            
                        <p><br />
                        <strong>En RIVERO LINDA VISTA SA DE CV concesionaria autorizada por GENERAL MOTORS DE M&Eacute;XICO, S. DE R. L. DE C.V con domicilio en: Ave Miguel Alem&aacute;n 5400 Col Torres de Linda vista CP 67126 Monterrey nuevo le&oacute;n (Matriz).</strong></p>

                        <p><br />
                        <strong>Rivero G&oacute;mez Mor&iacute;n</strong> Avenida G&oacute;mez Mor&iacute;n 402, Villas de Arag&oacute;n, CP 67273, San Pedro Garza Garc&iacute;a, Nuevo Le&oacute;n. (Matriz).</p>

                        <p><br />
                        <strong>Y con</strong> sucursales <strong>Rivero *Guadalupe en: Carretera a Reynosa y Ave M&eacute;xico CP 67199 Guadalupe, Nuevo Le&oacute;n, *Rivero Santa Catarina con direcci&oacute;n en Blvd. Gustavo D&iacute;az Ordaz 100-A, Col. La Fama, Santa Catarina, Nuevo Le&oacute;n, CP 66100, *Rivero Humberto Lobo Av. Humberto Lobo #660 cruz con Rio Rhin, Col. Del Valle, San Pedro Garza Garc&iacute;a, Nuevo Le&oacute;n, CP</strong> 66220 y <strong>*Rivero Vasconcelos Taller de Servicio</strong> Av. Jos&eacute; Vasconcelos #1555 Entre Neil Armstrong y Av. De los Conquistadores Col. Del Valle, San Pedro Garza Garc&iacute;a, Nuevo Le&oacute;n, CP 66220 hacemos de su conocimiento lo siguiente:</p>

                        <h3><br /><strong>B: DATOS PERSONALES QUE SE RECABAN</strong></h3>

                        <p><br />
                        Para las finalidades descritas en el presente aviso de privacidad, recabaremos las siguientes</p>

                        <p><br />
                        <strong>Para nuestros clientes</strong>: podremos recabar sus datos personales, y patrimoniales o financieros con la finalidad de prestar el servicio correspondiente, ofrecerle nuestro productos y cumplir con las obligaciones legales en virtud de lo anterior. Le informamos que obtenemos sus datos personales a trav&eacute;s de las siguientes formas:&nbsp;</p>

                        <ol>
                        <li><strong>Personalmente</strong>: Cuando usted acude al&nbsp;&nbsp;domicilio del &ldquo;Concesionario&rdquo;, en cualquiera de sus &aacute;reas de ventas, servicio, refacciones y/o m&oacute;dulos de apoyo de ventas para solicitar o adquirir nuestros productos o servicios.&nbsp;2. <strong>Directa</strong>: Cuando nos proporciona sus datos personales para solicitar o contratar nuestros productos o servicios, participar&nbsp;&nbsp;en promociones directas o indirectas, ofertas, sorteos, ya sea a trav&eacute;s de nuestra p&aacute;gina en Internet,&nbsp;&nbsp;v&iacute;a&nbsp;&nbsp;telef&oacute;nica o cualquier otro medio tecnol&oacute;gico.&nbsp;3. <strong>Indirecta</strong>: De cualquier otra fuente de informaci&oacute;n comercialmente disponibles o que sean permitidas por la Ley.&nbsp;</li>
                        </ol>

                        <p>Las categor&iacute;as de datos personales a recabar y sujetas a tratamiento son:&nbsp;</p>

                        <ul>
                        <li>a)&nbsp; &nbsp; Datos de identificaci&oacute;n&nbsp;<br />
                        b)&nbsp; &nbsp; Datos patrimoniales&nbsp;<br />
                        c)&nbsp; &nbsp; Datos financieros&nbsp;<br />
                        d)&nbsp; &nbsp; Datos acad&eacute;micos&nbsp;<br />
                        e)&nbsp; &nbsp;Datos laborales.<br />
                        f)&nbsp; &nbsp; Im&aacute;genes y sonidos (video grabaci&oacute;n). (publicaci&oacute;n en redes sociales, twitter, facebook, instalagram, pagina Web.)<br />
                        g)&nbsp;&nbsp;&nbsp;Datos familiares<br />
                        h)&nbsp;&nbsp;&nbsp;Datos sobre gustos y necesidades<br />
                        &nbsp;</li>
                        <li>El &ldquo;Concesionario&rdquo; como responsable incluido el personal de sus distintas &aacute;reas, manifiesta que no recaba datos sensibles como aquellos que puedan revelar aspectos como origen racial o &eacute;tnico, estado de salud presente y futuro, informaci&oacute;n gen&eacute;tica,&nbsp;&nbsp;creencias religiosas, filos&oacute;ficas y morales, afiliaci&oacute;n sindical, opiniones pol&iacute;ticas, preferencia sexual, cuestionario medico con padecimientos o enfermedades,&nbsp;&nbsp;intervenciones quir&uacute;rgicas, incapacidad, invalidez, deformidad, falta de un miembro, infecciones de transmisi&oacute;n sexual, uso de drogas, si se encuentra&nbsp;&nbsp;embarazada, historial m&eacute;dico de familiares con padecimientos o enfermedades, as&iacute; como los estudios m&eacute;dicos.&nbsp;</li>
                        </ul>

                        <p>Asimismo El &ldquo;Concesionario&rdquo; o su personal no podr&aacute;n recabar datos personales de menores de edad y personas en estado de interdicci&oacute;n o incapacidad establecida<br />
                        por ley, sin el consentimiento por escrito de la persona que ejerce la patria potestad, o en su caso, del tutor o representante legal, de conformidad con las reglas de<br />
                        representaci&oacute;n dispuestas en el C&oacute;digo Civil Federal.<br />
                        <br />
                        Persona moral: Acta Constitutiva., Identificaci&oacute;n Oficial del representante legal, Poder Notarial del Representante Legal., Comprobante de Domicilio., C&eacute;dula de RFC.<br />
                        Actas, protocolizaciones o inscripciones de asamblea que hayan modificado, el nombre, objeto o raz&oacute;n social de la persona moral., En caso de que comparezcan<br />
                        varias personas, representantes o testigos que haga menester identificar plenamente, una identificaci&oacute;n oficial de cada uno.<br />
                        <br />
                        Indistintamente, persona moral y/o persona f&iacute;sica:&nbsp;&nbsp;&nbsp;Tel&eacute;fono(s) de Contacto., Direcci&oacute;n(es) de Correo Electr&oacute;nico de Contacto., Referencias bancarias.,<br />
                        Identificaciones con fotograf&iacute;a., Estados financieros para operaciones que ameriten estos supuestos., Cartas de recomendaci&oacute;n.</p>

                        <p>&nbsp;</p>

                        <h3><strong>C. FINALIDADES PARA EL USO DE LOS DATOS PERSONALES</strong><br />
                        &nbsp;</h3>

                        <p>Los datos personales que recabamos del titular, son necesarios para el cumplimiento de las obligaciones, la relaci&oacute;n y/o la prestaci&oacute;n de servicios que exista o pudiera llegar a existir entre el titular y <strong>GRUPO RIVERO</strong> siendo este &uacute;ltimo quien los utiliza, almacena, transmite o transfiere en la medida en que la ley lo permite, para cumplir con las obligaciones derivadas de la relaci&oacute;n jur&iacute;dica o no jur&iacute;dica que exista o llegara a existir con el titular.<br />
                        &nbsp;</p>

                        <p>El Tratamiento de datos personales del titular que se recaben, y salvo que el&nbsp;&nbsp;titular disponga lo contrario a trav&eacute;s del medio descrito en el presente Aviso de Privacidad, tiene como finalidad ser usados para proveerle los productos o servicios que ha solicitado o pueda solicitar, para dar seguimiento a sus solicitudes, reclamaciones o para realizar acciones de actualizaciones t&eacute;cnicas de los productos o servicios que hubiese adquirido, para mantener actualizados nuestros registros, evaluar la calidad del servicios brindados, para la asesor&iacute;a, contrataci&oacute;n, colocaci&oacute;n, comercializaci&oacute;n y operaci&oacute;n de productos y servicios financieros, beneficios, descuentos, estudios de mercado, informaci&oacute;n de&nbsp;&nbsp;antecedentes crediticios, obtenci&oacute;n de im&aacute;genes y sonidos captados por c&aacute;maras de video vigilancia para ser utilizados en la seguridad de los clientes que visitan las instalaciones, notificaciones sobre cambio en condiciones, para dar cumplimiento a nuestra relaci&oacute;n jur&iacute;dica y atender requerimientos legales de autoridades&nbsp;&nbsp;competentes.<br />
                        &nbsp;</p>

                        <p>Las finalidades previamente descritas, dieron origen y son necesarias para la relaci&oacute;n jur&iacute;dica con el &ldquo;Concesionario&rdquo;.&nbsp;</p>

                        <p>Asimismo, el &ldquo;Concesionario&rdquo;, podr&aacute; tratar sus datos personales para contactarlo,&nbsp;&nbsp;dise&ntilde;ar, desarrollar, analizar, enviar publicidad y promoci&oacute;n de productos y servicios (tanto propios como de nuestros socios de negocio, incluyendo servicios financieros y de arrendamiento), notificar sorteos, realizar encuestas, invitarlo a participar en la evaluaci&oacute;n de nuestros procesos de calidad en la atenci&oacute;n de clientes, pudiendo realizar acciones comerciales, de mercadotecnia, estad&iacute;stica y de prospecci&oacute;n, as&iacute; como contactarlo para realizar:</p>
                    
                        <ul>
                        <li>a) Estudios sobre h&aacute;bitos de consumo, b) estudios de mercado. El titular tiene un plazo de cinco d&iacute;as&nbsp;&nbsp;h&aacute;biles posteriores a la fecha en que se obtuvieron sus datos personales, para que, de ser el caso, manifieste por escrito en el domicilio de El &ldquo;Concesionario&rdquo; (ante el &ldquo;Encargado&rdquo; de Datos Personales), su negativa para el tratamiento con respecto a las finalidades que no son necesarias, ni dieron origen a la&nbsp;&nbsp;relaci&oacute;n jur&iacute;dica con el responsable</li>
                        </ul>

                        <p>As&iacute; mismo hacemos de su conocimiento que sus datos personales pueden ser utilizados por <strong>GRUPO RIVERO</strong>; mercadot&eacute;cnicos, publicitarios o de prospecci&oacute;n comercial,<br />
                        as&iacute; como la transferencia por cualquier medio de sus datos personales a nuestras partes relacionadas, proveedores y/o t&eacute;rminos para que los mismo puedan ofrecerles en<br />
                        nombre de <strong>GRUPO RIVERO</strong> nuestros productos o servicios o bien los productos o servicio de ellos mismos.</p>

                        <p><strong>Para nuestros clientes:</strong></p>

                        <p>La realizaci&oacute;n de todas y cada una de las operaciones y la prestaci&oacute;n de servicio, inscribirlo, darlo de alta en nuestro sistema y administrar su expediente, recibir pagos,<br />
                        emitir facturas, conocer y atender sus quejas sugerencias o comentarios. La gesti&oacute;n de cobranza buscando la recuperaci&oacute;n de cartera vencida, enviar notificaciones, avisos y<br />
                        propaganda de nuestros productos o servicios, envi&oacute; de notificaciones de cambio a este aviso de privacidad.</p>

                        <p>&nbsp;</p>

                        <h3><strong>C. FINALIDADES SECUNDARIAS PARA EL USO DE LOS DATOS PERSONALES</strong></h3>

                        <p><br />
                        La realizaci&oacute;n de encuestas de calidad, servicio o de cualquier otro tipo relacionado con los productos que ofrece los servicios que presta y actos que celebra, as&iacute; como el desarrollo e implementaci&oacute;n de procesos de an&aacute;lisis de informaci&oacute;n y estad&iacute;sticos de dichas encuestas La promoci&oacute;n de productos, servicios, beneficios adicionales, descuentos, promociones, concursos, estudios de mercado, sorteos y publicidad en general, ofrecidos por las empresas integrantes del <strong>GRUPO RIVERO</strong> o relacionados con las mismas o cualquiera de dichas responsables mantenga alianzas comerciales. En caso de que usted no desee que sus datos personales sean trasladados ni transferidos para estos fines adicionales, usted podr&aacute; manifestarlo inmediatamente en la apertura de su contrato o solicitud, de lo contrario se entender&aacute; que usted acepta el uso de sus datos para estos fines, adicionalmente usted podr&aacute; en todo momento solicitar su derecho de oposici&oacute;n para estas finalidades a trav&eacute;s de procedimientos se&ntilde;alados en la secci&oacute;n de &ldquo;DERECHOS DE LOS TITULARES&rdquo; de nuestro aviso de privacidad integral. La negativa para el uso de sus datos personales para estas finalidades adicionales no podran ser un motivo para que se le niegue los servicios y/o contrata con el responsable.</p>

                        <p><br />
                        En virtud de los servicios contratados por los clientes, empleados y proveedores, as&iacute; como del inter&eacute;s mutuo con postulantes, <strong>GRUPO RIVERO</strong> maneja y de tratamiento a datos financieros o patrimoniales de los titulares con esas caracter&iacute;sticas.</p>

                        <p>&nbsp;</p>
                        
                        <h3><strong>D.-OTROS MEDIOS PARA LIMITAR EL USO O DIVULGACI&Oacute;N DE SUS DATOS PERSONALES.</strong></h3>

                        <p><br />
                        Usted puede inscribir su n&uacute;mero telef&oacute;nico en el Registro P&uacute;blico para Evitar Publicidad (REPEP) la Procuradur&iacute;a Federal del Consumidor (PROFECO), cuando no<br />
                        desee que su informaci&oacute;n sea utilizada para fines mercado l&oacute;gicos o publicitarios o que no desee recibir publicidad, para lo cual deber&aacute; ingresar a la p&aacute;gina:<br />
                        www.profeco.gob.mx<br />
                        <br />
                        Tambi&eacute;n puede limitar el uso o divulgaci&oacute;n de sus datos personales inscribi&eacute;ndose en el Registro P&uacute;blico de Usuarios (REUS) que no desean informaci&oacute;n publicitaria<br />
                        de productos o servicios financieros, para lo cual debe ingresar a la p&aacute;gina: www.condusef.gob.mx</p>

                        <h3><br />
                        <strong>E.- TRANSFERENCIA Y REMISION DE LOS DATOS PERSONALES</strong></h3>

                        <p><br />
                        Los datos que les fueron requeridos en esta operaci&oacute;n, son confidenciales y ser&aacute;n utilizados exclusivamente para documentar, en este sentido les informamos que sus datos<br />
                        personales podr&aacute;n ser proporcionados a terceros como<br />
                        <br />
                        <strong>Para nuestros clientes:</strong><br />
                        <br />
                        Datos a la planta general motors S de RL de CV, a instituciones bancarias, instituciones de cr&eacute;dito, empresas aseguradoras, a proveedores para el cumplimiento de los servicios, a la autoridad competente cuando lo requiera, a terceros que requieran informaci&oacute;n para el ejercicio de un derecho o de una acci&oacute;n, a instituciones nacionales e internacionales reguladoras de los servicios, servicio de administraci&oacute;n tributaria para cumplir con la normatividad fiscal, empresas de nuestro mismo grupo, las cuales operan bajo las mismas pol&iacute;ticas y procesos internos en materia de protecci&oacute;n de datos personales con la finalidad de brindarle un mejor servicio.</p>

                        <p>Y en los casos se&ntilde;alados en el art&iacute;culo 37 y dem&aacute;s art&iacute;culos aplicables de la ley federal de la protecci&oacute;n de datos personales en posesi&oacute;n de los particulares (&ldquo;LA LEY&rdquo;).</p>

                        <p>&nbsp;</p>

                        <h3><strong>F.- IMAGENES Y SONIDOS RECABADOS POR CAMARAS DE VIDEO VIGILANCIA.</strong><br />
                        &nbsp;</h3>

                        <p>Las im&aacute;genes que se recaben por medio de c&aacute;maras de video vigilancia de GRUPORIVERO ser&aacute;n utilizados para fines de seguridad y control interno, tal como se especifica<br />
                        en el aviso de privacidad- zona video grabada que podr&aacute; consultar en las &aacute;reas que son video grabadas.<br />
                        &nbsp;</p>

                        <h3><strong>G. - COOKIES Y WEB BEACONS</strong></h3>

                        <p><br />
                        Con la finalidad de mejorar la experiencia de sus usuarios en su sitio Web, se le informa que el portal podr&aacute; utilizar &ldquo;cookies&rdquo; y &ldquo;web beacons&rdquo; u otras tecnolog&iacute;as a trav&eacute;s de las cuales es posible monitorear el comportamiento del usuario de Internet. Para efectos del presente aviso de privacidad, &ldquo;cookies&rdquo; se identificara como los archivos de texto de informaci&oacute;n que un sitio web trasfiere al disco duro de la computadora del usuario con el fin de almacenar ciertos registros y preferencias.<br />
                        <br />
                        Por su parte las &ldquo;web beacons&rdquo; son im&aacute;genes insertadas en una p&aacute;gina de Internet o correo electr&oacute;nico que pueden ser utilizadas para monitorear el comportamiento de un visitante el portal web solo recaba informaci&oacute;n sobre la direccion I.P. y el tiempo de interacci&oacute;n en el sitio web. Estas cookies y otras tecnolog&iacute;as pueden ser deshabilitadas para conocer como hacerlo consulte las herramientas y/o preferencias de su navegador de Internet.<br />
                        <br />
                        <strong>H.- CAMBIOS AL AVISO DE PRIVACIDAD.</strong><br />
                        &nbsp;<br />
                        Cualquier cambio al presente aviso de privacidad, le ser&aacute; informado a trav&eacute;s de cualquiera de los siguientes medios:&nbsp;<br />
                        <br />
                        1. Avisos en el domicilio del &ldquo;Concesionario&rdquo;;&nbsp;<br />
                        2. Avisos en el portal de Internet del &ldquo;Concesionario&rdquo;(www.gruporivero.com.mx);&nbsp;<br />
                        3. Cualquier otro medio de comunicaci&oacute;n p&uacute;blico o privado previstos en los contratos que amparan las operaciones&nbsp;&nbsp;celebradas entre el Titular y el &ldquo;Concesionario&rdquo;.</p>

                        <p>&nbsp;</p>

                        <h3><strong>I.- Derechos &ldquo;ARCO&rdquo;</strong></h3>

                        <p><br />
                        Conformo a la ley federal de protecci&oacute;n de datos personales en posesi&oacute;n de particulares, usted por si o mediante representante legal debidamente acreditado, podr&aacute; ejercer sus derechos de acceso, rectificaci&oacute;n, cancelaci&oacute;n y oposici&oacute;n &ldquo;ARCO&rdquo; directamente ante un encargado de protecci&oacute;n de datos personales de GRUPO RIVERO.<br />
                        <br />
                        <strong>1.- ACCESO</strong>.- usted, en tomo momento podr&aacute; solicitar conocer que datos suyos han sido recabados y conserva el responsable&nbsp;<strong>2.- RECTIFICACION</strong>.- en caso de que alguno de sus datos sea inexacto o incompleto podr&aacute; solicitar su correcci&oacute;n, debiendo adjuntar la documentaci&oacute;n que acredite dicha modificaci&oacute;n. <strong>3.- CANCELACION</strong>.- podr&aacute; solicitarse en todo momento la cancelaci&oacute;n de los datos cuando considere que los mismos no est&aacute;n siendo tratados conforme a los principios y deberes establecidos en la legislaci&oacute;n. En caso de ser procedente la solicitud, los datos entraran al periodo de bloqueo en el cual no podran ser tratados de ninguna manera para su posterior supresi&oacute;n. <strong>4.- OPOSICION</strong>.- en todo momento el titular de los datos podran negarse por causa justificada al tratamiento de sus datos personales Para facilitar el ejercicio de sus derechos solo es necesario que lo solicite usted o su representante legal enviando un correo a la direccion<a href="mailto: privacidad@gruporivero.com"> privacidad@gruporivero.com </a>a trav&eacute;s de la cual se le brindara atenci&oacute;n a su solicitud y/o resolver&aacute; cualquier duda al respecto. Para ejercer los derechos ARCO deber&aacute; seguir el siguiente procedimiento.</p>

                        <p>&nbsp;</p>

                        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <strong>Paso 1</strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;--------------------&#8594;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<strong>Paso 2</strong><br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Solicitar el ejercicio de sus derechos&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;la persona asignada analizara el caso<br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;ARCO al la cuenta<a href="mailto: privacidad@gruporivero.com"> privacidad@gruporivero.com</a><br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&#8595;<br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <strong>Paso 4</strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &#8592;--------------------&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <strong>Paso 3</strong><br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; En caso de proceder se llevara a cabo&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;realizada la solicitud y con requisitos<br />
                        &nbsp; &nbsp; &nbsp; &nbsp;La ejecuci&oacute;n de los derechos arco completos se tiene un m&aacute;ximo de<br />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; (Durante los siguientes 15 d&iacute;as)</p>

                        <p>&nbsp;</p>


                        <h3><strong>J.- PROTECCI&Oacute;N DE DERECHOS ANTE EL INAI</strong></h3>

                        <p><br />
                        El titular de los datos personales tiene el derecho que le asiste la Ley (LFPDPPP) y su Reglamento, para acudir ante el &nbsp;Instituto Nacional de Transparencia, Acceso a la Informaci&oacute;n y Protecci&oacute;n de Datos Personales(INAI) en caso de considerar que su derecho a la protecci&oacute;n de datos personales ha sido vulnerado, para lo cual tambi&eacute;n puede ingresar a la p&aacute;gina: https://home.inai.org.mx/<br />
                        <br />
                        Este aviso de privacidad, el tratamiento de sus datos personales y/o todos los documentos relacionados se rigen por la ley federal de protecci&oacute;n de datos personales en posesi&oacute;n de los particulares y las dem&aacute;s leyes y reglamentos de los estados unidos mexicanos.La aceptaci&oacute;n de este aviso de privacidad o la simple solicitud de servicio una vez publicado y puesta a disposici&oacute;n el presente aviso de privacidad implica una aceptaci&oacute;n expresa por parte del titular de los t&eacute;rminos del mismo y su sometimiento expreso a los tribunales competentes de la cuidad de nuevo le&oacute;n, para cualquier controversia o reclamaci&oacute;n de este aviso de privacidad.</p>

                        <p>&nbsp;</p>

                        <center>
                        <p>Fecha de creaci&oacute;n:&nbsp;&nbsp;&nbsp;8 de julio del 2013&nbsp;<br />
                        &Uacute;ltima Modificaci&oacute;n: 1 de Enero del 2022<br />
                        Versi&oacute;n: 3 aviso clientes concesionaria</p>

                        <p>(1) Remisi&oacute;n: La comunicaci&oacute;n de datos personales entre el responsable y el encargado, dentro o fuera del territorio mexicano</p>
                        </center>

                        <p>&nbsp;</p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )

}